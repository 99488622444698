.App {
  text-align: center;
}

.container {
  overflow-anchor: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  background: #fff;
  color: #000 !important;
  font-weight: 400 !important;
}
.box {
  transition: box-shadow .3s;
  border-radius:10px;
  border: 1px solid #EEE;
  background: #fff;  
}
.box:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}
.price {
    font-weight: bold;
}
.shoprow {
    display:flex;
}
.description {
    max-width: 700px;
    justify-content: center;
}
.tableHDR {
    font-weight: bold;
}

.headerHR {
  border: 1px solid #000;
  width: 100%;
}
.itemHR {
  border: 1px solid #EEE;
  width: 100%;
}

.itemDelete {
    color: #000;
}

.itemDelete:hover {
    color: red;
    cursor: pointer;
}

.disabledtext {
    color: #AAA;
}

.lselect {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: auto; 
 }
 .lselect:hover {
    border-color: #dbdbdb; 
}
  .lselect:active, .lselect.open, .lselect:focus {
    border-color: #999; }
  .lselect:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    width: 5px;
    }
  .lselect.open:after {
    -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .lselect.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0); }
  .lselect.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .lselect.disabled:after {
      border-color: #cccccc; }
  .lselect.wide {
    width: 100%; }
    .lselect.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .lselect.right {
    float: right; }
    .lselect.right .list {
      left: auto;
      right: 0; }
  .lselect.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .lselect.small:after {
      height: 4px;
      width: 4px; }
    .lselect.small .option {
      line-height: 34px;
      min-height: 34px; }
  .lselect .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
            transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .lselect .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .lselect .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
    .lselect .option:hover, .lselect .option.focus, .lselect .option.selected.focus {
      background-color: #f6f6f6; }
    .lselect .option.selected {
      font-weight: bold; }
    .lselect .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .lselect .list {
  display: none; }

.no-csspointerevents .lselect.open .list {
  display: block; }

.strikeHeader {
    color: #B6B6B6 !important;
    font-size: 16px;
}
.titleArea {
    text-transform: uppercase;
    padding-top: 36px;
    padding-bottom: 36px;
    background-color: #908F8F;
}
.titleArea h2{
    color: #FFF !important;
}

#page {
 position: relative;
  min-height: 100vh;
}

#contentwrap {
  padding-bottom: 18rem;    /* Footer height */
}
.footerArea {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    height: 18em;
}
  .footerArea::after {
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }
  .footerArea .main-footerArea {
    position: relative;
    z-index: 1;
    padding-top: 80px; }
  .footerArea .single-footer-widget {
    position: relative;
    z-index: 1;
    margin-bottom: 70px; }
    .footerArea .single-footer-widget p {
      color: #b7b7b7;
      margin-bottom: 20px; }
    .footerArea .single-footer-widget .social-info a {
      display: inline-block;
      border: 1px solid #b7b7b7;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      color: #ffffff;
      margin-right: 5px;
      line-height: 38px; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .footerArea .single-footer-widget .social-info a {
          width: 30px;
          height: 30px;
          line-height: 28px;
          font-size: 14px; } }
      .footerArea .single-footer-widget .social-info a:hover, .footerArea .single-footer-widget .social-info a:focus {
        border-color: #70c745;
        background-color: #70c745; }
    .footerArea .single-footer-widget .widget-title h5 {
      font-size: 22px;
      color: #ffffff;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .footerArea .single-footer-widget .widget-nav ul {
      position: relative;
      z-index: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .footerArea .single-footer-widget .widget-nav ul li {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%; }
        .footerArea .single-footer-widget .widget-nav ul li a {
          display: block;
          color: #b7b7b7;
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 11px; }
          .footerArea .single-footer-widget .widget-nav ul li a:hover, .footerArea .single-footer-widget .widget-nav ul li a:focus {
            color: #ffffff; }
    .footerArea .single-footer-widget .single-best-seller-product {
      position: relative;
      z-index: 1;
      margin-bottom: 20px; }
      .footerArea .single-footer-widget .single-best-seller-product::after {
        margin-bottom: 0; }
      .footerArea .single-footer-widget .single-best-seller-product .product-thumbnail {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70px;
        flex: 0 0 70px;
        max-width: 70px;
        width: 70px;
        margin-right: 30px; }
      .footerArea .single-footer-widget .single-best-seller-product .product-info a {
        display: block;
        color: #b7b7b7;
        font-size: 16px;
        font-weight: 400; }
        .footerArea .single-footer-widget .single-best-seller-product .product-info a:hover, .footerArea .single-footer-widget .single-best-seller-product .product-info a:focus {
          color: #ffffff; }
      .footerArea .single-footer-widget .single-best-seller-product .product-info p {
        margin-bottom: 0;
        color: #ffffff;
        font-weight: 500; }
    .footerArea .single-footer-widget .contact-information p {
      line-height: 1.3;
      color: #ffffff;
      margin-bottom: 13px; }
      .footerArea .single-footer-widget .contact-information p span {
        color: #b7b7b7; }
      .footerArea .single-footer-widget .contact-information p:last-child {
        margin-bottom: 0; }
  .footerArea .footer-bottom-area {
    position: relative;
    z-index: 1; }
    .footerArea .footer-bottom-area .border-line {
      width: 100%;
      height: 1px;
      background-color: #34513d; }
    .footerArea .footer-bottom-area .copywrite-text {
      position: relative;
      z-index: 1;
      padding: 10px 0; }
      @media only screen and (max-width: 767px) {
        .footerArea .footer-bottom-area .copywrite-text {
          text-align: center;
          padding-bottom: 0; } }
      .footerArea .footer-bottom-area .copywrite-text p {
        font-size: 14px;
        color: #b7b7b7;
        font-weight: 400;
        margin-bottom: 0; }
        @media only screen and (max-width: 767px) {
          .footerArea .footer-bottom-area .copywrite-text p {
            font-size: 12px; } }
        .footerArea .footer-bottom-area .copywrite-text p a {
          font-size: 14px;
          color: #ffffff;
          font-weight: 500; }
          .footerArea .footer-bottom-area .copywrite-text p a:hover, .footerArea .footer-bottom-area .copywrite-text p a:focus {
            color: #70c745; }
          @media only screen and (max-width: 767px) {
            .footerArea .footer-bottom-area .copywrite-text p a {
              font-size: 12px; } }
    .footerArea .footer-bottom-area .footer-nav {
      position: relative;
      z-index: 1;
      padding: 10px 0; }
      .footerArea .footer-bottom-area .footer-nav ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end; }
        @media only screen and (max-width: 767px) {
          .footerArea .footer-bottom-area .footer-nav ul {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center; } }
        .footerArea .footer-bottom-area .footer-nav ul li a {
          display: inline-block;
          color: #b7b7b7;
          font-size: 14px;
          font-weight: 400;
          margin: 0 15px; }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .footerArea .footer-bottom-area .footer-nav ul li a {
              margin: 0 8px; } }
          @media only screen and (max-width: 767px) {
            .footerArea .footer-bottom-area .footer-nav ul li a {
              margin: 0 5px; } }
          .footerArea .footer-bottom-area .footer-nav ul li a:hover, .footerArea .footer-bottom-area .footer-nav ul li a:focus {
            color: #ffffff; }
        .footerArea .footer-bottom-area .footer-nav ul li:last-child a {
          margin-right: 0; }
